'use strict';

import $ from 'jquery';
import whatInput from 'what-input';
// import ajaxLoadMore from './lib/ajaxLoadMore'; // uses PHP now

window.jQuery = $;

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

function share(em) {
    var shareurl = em.href;
    var top = (screen.availHeight - 500) / 2;
    var left = (screen.availWidth - 500) / 2;
    var popup = window.open(
        shareurl,
        'social sharing',
        'width=550,height=420,left=' + left + ',top=' + top + ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1'
    );
    return false;
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

jQuery(document).on('ready scroll', function () {
    var $body = jQuery('body');
    var topScroll = jQuery(document).scrollTop();
    if (topScroll >= 50) {
        $body.addClass('sticky_header');
    } else {
        $body.removeClass('sticky_header');
    }
});

jQuery(document).ready(function () {

    var $body = jQuery('body');

    setTimeout(function () {
        $body.removeClass('loading');
    }, 150);

    // ajaxLoadMore();

    var owl = jQuery(".owl-carousel");

    jQuery(".owl-carousel").owlCarousel({
        loop: true,
        autoWidth: false,
        margin: 150,
        lazyLoad: true,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: false,
        navigation: true,
        responsive: {
            0: {
                items: 1
            },
            425: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    });
    
    // Run check on load
    if ($('select.select_location').length) {
        $('select.select_location').each(function() {
            showHideApplyButtons(this);
        });
    }

    jQuery(document).on('change', 'select.select_location', function (e) {
        showHideApplyButtons(this);
    });

    // function startAjaxFilterItems() {

    //     var form = jQuery('form.webrandfilter');
    //     var ajaxurl = jQuery('main').data('url');

    //     $body.addClass('loading');

    //     jQuery.ajax({
    //         url: ajaxurl,
    //         data: form.serialize(),
    //         type: form.attr('method'),
    //         beforeSend: function (xhr) {
    //             form.find('button').text('Verwerken...'); // changing the button label
    //         },
    //         success: function (data) {
    //             form.find('button').text('Toepassen'); // changing the button label back
    //             jQuery('#ajax-response').html(data); // insert data
    //             setTimeout(() => {
    //                 $body.removeClass('loading');
    //             }, 150);
    //         },
    //     });
    //     return false;
    // }

    jQuery(document).on('change', '.sf-field-taxonomy-locatie select', function (e) {

        var $location = $(this).val();
        var $functie = '';
        var $dienstverband = '';

        if (getUrlParameter('_sft_functie')) {
            $functie = $('.sf-field-taxonomy-functie select').val();
        }

        if (getUrlParameter('_sft_dienstverband')) {
            $dienstverband = $('.sf-field-taxonomy-dienstverband select').val();
        }

        setTimeout(() => {
            if (getUrlParameter('_sft_locatie')) {
                window.location.href = "/vestiging/" + $location + "?_sft_functie" + $functie + "&_sft_dienstverband" + $dienstverband + "&_sft_locatie" + $location;
            }
        }, 1000);
    });

    // jQuery(document).on('click', '.load-more', function () {

    //     var that = $(this);
    //     var newPage = page + 1;
    //     var ajaxurl = jQuery('main').data('url');
    //     var page = that.data('page');
    //     var left = that.data('left');
    //     var visible = that.data('visible');

    //     that.addClass('loading');

    //     $.ajax({
    //         url: ajaxurl,
    //         type: 'post',
    //         data: {
    //             page: page,
    //             left: left,
    //             action: 'load_more_posts',
    //         },
    //         error: function (response) {
    //             console.log(response);
    //         },
    //         // error: function (response) {
    //         //     alert(response.responseText)
    //         // },
    //         success: function (response) {
    //             that.data('page', newPage);
    //             $('#ajax-response').append(response);
    //             visible = $('[data-taget]').length;
    //             that.removeClass('loading');

    //             setTimeout(() => {
    //                 $('.showed').text(visible);
    //                 if (visible >= found) {
    //                     $('.load-more').remove();
    //                 }
    //             }, 300);
    //         }
    //     });
    // });

    // jQuery(".carousel_next").click(function () {
    jQuery(document).on('click', '.carousel_next', function () {
        owl.trigger('owl.next');
    });

    // jQuery(".carousel_prev").click(function () {
    jQuery(document).on('click', '.carousel_prev', function () {
        owl.trigger('owl.prev');
    });


    /* -- Navigation
    ========================================== */

    // jQuery('.navigation').singlePageNav({
    //     offset: jQuery('#header').outerHeight(),
    //     filter: ':not(.external)', 
    //     updateHash: false,
    // });

    // jQuery('.navigation.footer').singlePageNav({
    //     offset: jQuery('#header').outerHeight(),
    //     filter: ':not(.external)',
    //     updateHash: false,
    // });

    jQuery('#mobile_navigation_button').click(function (e) {
        e.preventDefault();
        $body.toggleClass('mobile_menu_open');
    });

    jQuery('#mobile_navigation_options li').click(function (e) {
        jQuery('#header .navigation ul').slideToggle();
    });

    jQuery('.close_menu').click(function (e) {
        e.preventDefault();
        $body.removeClass('mobile_menu_open');
    });

    jQuery('#mobile_menu_navigation_options a').click(function (e) {
        e.preventDefault();
        var anchor = jQuery(this).attr('href');
        $body.removeClass('mobile_menu_open');

        jQuery('html,body').animate({
            scrollTop: jQuery(anchor).offset().top
        }, 300);
    });

    if (jQuery('.showed')) {
        jQuery('.showed').text(jQuery('.job').length);
    }

    /* -- Jobs filter
	========================================== */

    //jQuery('.jobs_list').mixItUp();

    // jQuery(".jobs_list").mixItUp({
    //     loadmore: {
    //         initial: 8,
    //         loadMore: 4, 
    //         buttonLabel: "Meer vacatures laden"
    //     },
    //     selectors: {
    //         target: ".job" 
    //     },
    //     layout: {
    //         display: "inline-block"
    //     },
    //     callbacks: {
    //         onMixEnd: function() {
    //             var count    = jQuery('.job:visible').length;
    //             var countAll = jQuery('.job').length;

    //             jQuery('.js-current-count').html(count);
    //             jQuery('.js-total-count').html(countAll);
    //         }
    //     }
    // });

    /* -- Culture carousel
    ========================================== */

    jQuery('#culture_gallery').lightSlider({
        item: 5,
        loop: false,
        slideMove: 2,
        slideMargin: 0,
        pager: false,
        controls: false,
        loop: true,
        auto: true,
        enableDrag: false,
        enableTouch: false,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        pause: 8000,
        speed: 8000,
        responsive: [{
                breakpoint: 1600,
                settings: {
                    item: 4,
                    slideMove: 1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    item: 3,
                    slideMove: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    item: 2,
                    slideMove: 1
                }
            }
        ]
    });


    /* -- Single vacancy header animation
    ========================================== */
    if ($body.hasClass('single-vacature')) {
        // if (jQuery(window).width() > 768) {

        //     jQuery(window).scroll(function () {
        //         if (jQuery(this).scrollTop() > 500) { //use `this`, not `document`
        //             jQuery('.header_right .socials').css({
        //                 'display': 'none'
        //             });
        //             jQuery('.single-vacature .header_right .apply_job_trigger').css({
        //                 'display': 'block'
        //             });

        //             jQuery('.single-vacature .header_right .social_text').css({
        //                 'display': 'block'
        //             });
        //         }

        //         if (jQuery(this).scrollTop() < 500) { //use `this`, not `document`
        //             jQuery('.header_right .socials').css({
        //                 'display': 'block'
        //             });
        //             jQuery('.single-vacature .header_right .apply_job_trigger').css({
        //                 'display': 'none'
        //             });

        //             jQuery('.single-vacature .header_right .social_text').css({
        //                 'display': 'none'
        //             });
        //         }

        //     });
        // }

        if (jQuery(window).width() < 960) {
            jQuery(window).scroll(function () {
                if (jQuery(this).scrollTop() < 595) {
                    console.log('header zichtbaar');
                    document.querySelector('.mobile_header_content').style.display = 'none';
                    document.querySelector('.header_center #logo').style.display = 'inline-block';
                }

                if (jQuery(this).scrollTop() > 595) {
                    console.log('header niet zichtbaar');
                    document.querySelector('.header_center #logo').style.display = 'none';
                    document.querySelector('.mobile_header_content').style.display = 'block';
                }
            })
        }
    }



    /* -- Testimonials carousel
    ========================================== */

    /*
    	var testimonials = jQuery('#testimonials_carousel').lightSlider({
            adaptiveHeight:true,
            pager: false,
            item:1,
            slideMargin:0,
            loop:true,
            controls: false,
            mode: 'fade'
        });

        jQuery('#testimonials_carousel_prev').click(function(){
            testimonials.goToPrevSlide();
        });
        jQuery('#testimonials_carousel_next').click(function(){
            testimonials.goToNextSlide();
        });
    */


    /*
    	jQuery('#testimonials_carousel').slick({
    		fade: true,
    		adaptiveHeight: true,
    		appendArrows: '#ervaringen',
    		nextArrow: '<button type="button" class="testimonials_nav testimonials_carousel_next"><i class="fa fa-chevron-right"></i></button>',
    		prevArrow: '<button type="button" class="testimonials_nav testimonials_carousel_prev"><i class="fa fa-chevron-left"></i></button>',
            focusOnSelect: false,
    	});
    */


    /* -- Apply to job form
	========================================== */


    // Normale sollicitatie
    jQuery('.apply_job_trigger_sollicitatie').click(function (e) {
        e.preventDefault();
        jQuery('.side_pannel_sollicitatie').animate({
            right: '-=520'
        }, 300);
        jQuery('.apply_job_overlay').fadeOut();
        $body.removeClass('apply_job_active');
        jQuery('html').removeClass('apply_job_active');
    }, function () {
        jQuery('.side_pannel_sollicitatie').animate({
            right: '+=520'
        }, 300);
        //jQuery('#input_1_1_3').focus();
        jQuery('#input_1_12').val(jQuery('#location_value').val());
        jQuery('.apply_job_overlay').fadeIn();
        $body.addClass('apply_job_active');
        jQuery('html').addClass('apply_job_active');
    });

    // Open sollicitatie
    jQuery('.apply_job_trigger_open_sollicitatie').click(function (e) {
        e.preventDefault();
        jQuery('.side_pannel_open_sollicitatie').animate({
            right: '-=520'
        }, 300);
        jQuery('.apply_job_overlay').fadeOut();
        $body.removeClass('apply_job_active');
        jQuery('html').removeClass('apply_job_active');
    }, function () {
        jQuery('.side_pannel_open_sollicitatie').animate({
            right: '+=520'
        }, 300);
        //jQuery('#input_1_1_3').focus();
        jQuery('#input_1_12').val(jQuery('#location_value').val());
        jQuery('.apply_job_overlay').fadeIn();
        $body.addClass('apply_job_active');
        jQuery('html').addClass('apply_job_active');
    });

    jQuery('.apply_job_overlay').click(function (e) {
        e.preventDefault();
        jQuery('.side_pannel').animate({
            right: '-=520'
        }, 300);
        jQuery('.apply_job_overlay').fadeOut();
        $body.removeClass('apply_job_active');
        jQuery('html').removeClass('apply_job_active');
    });

    jQuery('.close_button').click(function (e) {
        e.preventDefault();
        jQuery(this).parent().animate({
            right: '-=520'
        }, 300);
        jQuery('.apply_job_overlay').fadeOut();
        $body.removeClass('apply_job_active');
        jQuery('html').removeClass('apply_job_active');
    });


    if (getUrlParameter('open_sollicitatie')) {
        jQuery('.side_pannel_open_sollicitatie').animate({
            right: '+=520'
        }, 300);
        jQuery('.apply_job_overlay').fadeIn();
        $body.addClass('apply_job_active');
        jQuery('html').addClass('apply_job_active');
    }

    if (getUrlParameter('sollicitatie')) {
        jQuery('.side_pannel_sollicitatie').animate({
            right: '+=520'
        }, 300);
        jQuery('.apply_job_overlay').fadeIn();
        $body.addClass('apply_job_active');
        jQuery('html').addClass('apply_job_active');
    }



    /* -- Apply to job form - submit
    ========================================== */

    var submit_form = getUrlParameter('submit');

    if (submit_form) {
        jQuery('#form_submit').animate({
            right: '+=520'
        }, 300);
        jQuery('#form_submit_overlay').fadeIn();
    }

    jQuery('#form_submit_overlay').click(function () {
        jQuery('#form_submit').animate({
            right: '-=520'
        }, 300);
        jQuery('#form_submit_overlay').fadeOut();
    });

    jQuery('#form_submit_close').click(function () {
        jQuery('#form_submit').animate({
            right: '-=520'
        }, 300);
        jQuery('#form_submit_overlay').fadeOut();
    });

    /* -- Instagram feed
    ========================================== */

    jQuery('#instagram_trigger').click(function () {
        jQuery('#instagram_feed').animate({
            right: '-=520'
        }, 300);
        jQuery('#instagram_feed_overlay').fadeOut();
    }, function () {
        jQuery('#instagram_feed').animate({
            right: '+=520'
        }, 300);
        jQuery('#instagram_feed_overlay').fadeIn();
    });

    jQuery('#instagram_feed_overlay').click(function () {
        jQuery('#instagram_feed').animate({
            right: '-=520'
        }, 300);
        jQuery('#instagram_feed_overlay').fadeOut();
    });

    jQuery('#instagram_feed_close').click(function () {
        jQuery('#instagram_feed').animate({
            right: '-=520'
        }, 300);
        jQuery('#instagram_feed_overlay').fadeOut();
    });

    /* -- Scroll to element
    ========================================== */

    jQuery(".scroll_down").click(function () {
        jQuery('html, body').animate({
            scrollTop: jQuery(".vacatures").offset().top - 90
        }, 1000);
    });

    function showHideApplyButtons(e) {
        var parent = $(e).closest('.job');
        var value = $(e).val();
    
        parent.find('.initial_button').addClass('hide');
        parent.find('[data-location]').addClass('hide');
        parent.find('[data-location="' + value + '"]').removeClass('hide');
    }
});

/* -- Shortcodes libs
========================================== */

(function (e) {
    e.fn.extend({
        tabs: function (t) {
            var n = {
                type: "default",
                width: "auto",
                fit: true,
                closed: false,
                update_hash: false,
                auto: false,
                autoInterval: 6e3,
                prev: "",
                next: "",
                pause_resume: "",
                activate: function () {}
            };
            var t = e.extend(n, t);
            var r = t,
                i = r.type,
                s = r.fit,
                o = r.width,
                u = r.update_hash,
                a = "vertical",
                f = "accordion";
            var l = window.location.hash;
            var c = !!(u && window.history && history.replaceState);
            e(this).bind("tabactivate", function (e, n) {
                if (typeof t.activate === "function") {
                    t.activate.call(n, e)
                }
            });
            this.each(function () {
                function h() {
                    if (i == a) {
                        n.addClass("tabs_vertical")
                    }
                    if (s == true) {
                        n.css({
                            width: "100%",
                            margin: "0px"
                        })
                    }
                    if (i == f) {
                        n.addClass("accordion_group");
                        n.find(".tabs_nav").css("display", "none")
                    }
                }

                function T(e) {
                    r.find("[role=tab]").eq(e).trigger("click", ["is_triggered"])
                }

                function N() {
                    E = r.find(".active").index();
                    E === w ? E = 0 : E = E + 1;
                    T(E)
                }
                var n = e(this);
                var r = n.find("ul.tabs_nav");
                var u = n.attr("id");
                n.find("ul.tabs_nav li").addClass("tabs_nav_item");
                n.css({
                    display: "block",
                    width: o
                });
                n.find(".tabs_container > div").addClass("tabs_content");
                h();
                var p;
                n.find(".tabs_content").before("<span class='accordion_title' role='tab'><span class='accordion_icon'></span></span>");
                var d = 0;
                n.find(".accordion_title").each(function () {
                    p = e(this);
                    var t = n.find(".tabs_nav_item:eq(" + d + ")");
                    var r = n.find(".accordion_title:eq(" + d + ")");
                    r.append(t.html());
                    r.data(t.data());
                    p.attr("aria-controls", "tab_item-" + d);
                    d++
                });
                var v = 0,
                    m;
                n.find(".tabs_nav_item").each(function () {
                    $tabItem = e(this);
                    $tabItem.attr("aria-controls", "tab_item-" + v);
                    $tabItem.attr("role", "tab");
                    var t = 0;
                    n.find(".tabs_content").each(function () {
                        m = e(this);
                        m.attr("aria-labelledby", "tab_item-" + t);
                        t++
                    });
                    v++
                });
                var g = 0;
                if (l != "") {
                    var y = l.match(new RegExp(u + "([0-9]+)"));
                    if (y !== null && y.length === 2) {
                        g = parseInt(y[1], 10) - 1;
                        if (g > v) {
                            g = 0
                        }
                    }
                }
                e(n.find(".tabs_nav_item")[g]).addClass("active");
                if (t.closed !== true && !(t.closed === "accordion" && !r.is(":visible")) && !(t.closed === "tabs" && r.is(":visible"))) {
                    e(n.find(".accordion_title")[g]).addClass("active");
                    e(n.find(".tabs_content")[g]).addClass("tabs_content-active").attr("style", "display:block")
                } else {
                    e(n.find(".tabs_content")[g]).addClass("tabs_content-active accordion_title-closed")
                }
                var b = r.find("[role=tab]").length;
                var w = b - 1;
                var E;
                var S;
                var x = false;
                if (t.auto === true) {
                    S = setInterval(N, t.autoInterval);
                    n.on({
                        mouseenter: function () {
                            if (x === false) {
                                clearInterval(S)
                            }
                        },
                        mouseleave: function () {
                            if (x !== true) {
                                S = setInterval(N, t.autoInterval)
                            }
                        }
                    })
                }
                if (e(t.prev).length > 0) {
                    e(t.prev).on("click", function (e) {
                        e.preventDefault();
                        E = r.find(".active").index();
                        E === 0 ? E = w : E = E - 1;
                        T(E);
                        n.trigger("prev_item")
                    })
                }
                if (e(t.next).length > 0) {
                    e(t.next).on("click", function (e) {
                        e.preventDefault();
                        E = r.find(".active").index();
                        E === w ? E = 0 : E = E + 1;
                        T(E);
                        n.trigger("next_item")
                    })
                }
                if (e(t.pause_resume).length > 0) {
                    e(t.pause_resume).on("click", function (n) {
                        n.preventDefault();
                        if (x === false) {
                            x = true;
                            clearInterval(S);
                            e(t.pause_resume).trigger("paused")
                        } else {
                            x = false;
                            S = setInterval(N, t.autoInterval);
                            e(t.pause_resume).trigger("resumed")
                        }
                    })
                }
                n.find("[role=tab]").each(function () {
                    var r = e(this);
                    r.on("click", function (r, i) {
                        var s = e(this);
                        var o = s.attr("aria-controls");
                        if (s.hasClass("accordion_title") && s.hasClass("active")) {
                            n.find(".tabs_content-active").slideUp("", function () {
                                e(this).addClass("accordion_title-closed")
                            });
                            s.removeClass("active");
                            return false
                        }
                        if (!s.hasClass("active") && s.hasClass("accordion_title")) {
                            n.find(".active").removeClass("active");
                            n.find(".tabs_content-active").slideUp().removeClass("tabs_content-active accordion_title-closed");
                            n.find("[aria-controls=" + o + "]").addClass("active");
                            n.find(".tabs_content[aria-labelledby = " + o + "]").slideDown().addClass("tabs_content-active")
                        } else {
                            n.find(".active").removeClass("active");
                            n.find(".tabs_content-active").removeAttr("style").removeClass("tabs_content-active").removeClass("accordion_title-closed");
                            n.find("[aria-controls=" + o + "]").addClass("active");
                            n.find(".tabs_content[aria-labelledby = " + o + "]").fadeIn().addClass("tabs_content-active")
                        }
                        s.trigger("tabactivate", s);
                        if (typeof S !== "undefined" && typeof i === "undefined") {
                            x = true;
                            clearInterval(S);
                            if (e(t.pause_resume).length > 0) {
                                e(t.pause_resume).trigger("paused", ["is_tab_clicked"])
                            }
                        }
                        if (c) {
                            var a = window.location.hash;
                            var f = u + (parseInt(o.substring(9), 10) + 1).toString();
                            if (a != "") {
                                var l = new RegExp(u + "[0-9]+");
                                if (a.match(l) != null) {
                                    f = a.replace(l, f)
                                } else {
                                    f = a + "|" + f
                                }
                            } else {
                                f = "#" + f
                            }
                            history.replaceState(null, null, f)
                        }
                    })
                });
                e(window).resize(function () {
                    n.find(".accordion_title-closed").removeAttr("style")
                })
            })
        }
    })
})(jQuery)